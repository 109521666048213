import { Autocomplete, Button, Grid, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import ContentMainBox from '../../../components/MainBox'
import { useAuthContext } from '../../../context/userContext'
import { useGetCarreras, useGetCiclos, useGetGeneros } from '../../../hooks/useTicket'
import { useGetAlumnosDeTutor } from '../../../hooks/useTutorAlumno'
import { ButtonCitaMedica } from './components/CitaMedica/ButtonCitaMedica'
import CreateTicket from './components/Ticket/CreateTicket'
import { ButtonVerSemaforos } from './components/Semaforo/ButtonVerSemaforos'

const columns = [
    {
        headerClassName: 'super-app-theme--header',
        field: 'codEstudiante',
        headerName: 'Código',
        type: 'string',
        width: 100,
        flex:1,
    },
    {
        headerClassName: 'super-app-theme--header',
        field: 'dniEstudiante',
        headerName: 'DNI',
        type: 'string',
        width: 100,
        flex:1,
    },
    {
        headerClassName: 'super-app-theme--header',
        field: 'nomEstudiante',
        headerName: 'Nombre Alumno',
        flex:3,
        width: 280,
        type: 'string',
    },
    {
        headerClassName: 'super-app-theme--header',
        field: 'accion',
        type: 'actions',
        flex:2,
        width: 180,
        headerName: 'Acción',
        cellClassName: 'accion',
        getActions: ({ row }) => {
            return [
                <ButtonCitaMedica codEstudiante={row.codEstudiante} />,
                <ButtonVerSemaforos dniEstudiante={row.dniEstudiante}/>
            ]
        }
    },
]

export const CrearTicket = () => {
    const { user } = useAuthContext()
    const mutation = useGetAlumnosDeTutor()

    let { data: generos } = useGetGeneros() || []
    const comboGeneros =
        generos ?
            generos.map((genero) => {
                return { value: genero.descripcion.toUpperCase(), label: genero.descripcion }
            }) : []
    let { data: ciclos } = useGetCiclos() || []
    const comboCiclos =
        ciclos ?
            ciclos.map((ciclo) => {
                return { value: ciclo.codCiclo, label: ciclo.descripcion }
            }) : []

    const [ciclo, setCiclo] = useState('')
    const [genero, setGenero] = useState('')
    const [carrera, setCarrera] = useState('')
    const [alumnosSeleccionados, setAlumnosSeleccionados] = useState([])
    const { data: carreras } = useGetCarreras(user?.codigo)
    const comboCarreras =
        carreras ?
            carreras.map((carrera) => {
                return { value: carrera.codCarrera, label: carrera.nomCarrera }
            }) : []
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const alumnos = mutation.data
    const [dataAlumnos, setDataAlumnos] = useState([])
    const [alumno, setAlumno] = useState(null);
    const [filter, setFilter] = useState(false);
    const comboAlumnos =
        alumnos &&
        alumnos.map((unidad) => {
            return unidad.nomEstudiante

        })
    const onFilter = () => {
        let newAlumnos =
            alumnos &&
            alumnos.filter((unidad) => {
                if (genero !== '') {
                    if (unidad.genero === genero) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        newAlumnos =
            newAlumnos &&
            newAlumnos.filter((unidad) => {
                if (alumno !== null) {
                    if (unidad.nomEstudiante === alumno) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        newAlumnos =
            newAlumnos &&
            newAlumnos.filter((unidad) => {
                if (carrera !== '') {
                    if (unidad.codCarrera === carrera) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        newAlumnos =
            newAlumnos &&
            newAlumnos.filter((unidad) => {
                if (ciclo !== '') {
                    if (unidad.ciclo === ciclo) {
                        return unidad
                    }
                }
                else {
                    return unidad
                }
            })
        setDataAlumnos(newAlumnos)
        setFilter(true)
        if (genero === '' && carrera === '' && ciclo === '' && alumno === null) {
            setDataAlumnos(alumnos)
        }
    }
    const handleChangeCarrera = (event) => {
        setCarrera(event.target.value);
    };

    useEffect(() => {
        mutation.mutate(user.codigo)
    }, [])
    
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <div className='flex justify-between'>
                        Crear Tickets
                    </div>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <Grid container spacing={2} columns={15}>
                        <Grid item xs={3} className='mt-4'>
                            <TextField
                                fullWidth
                                id="sexo"
                                label="Sexo"
                                select
                                value={genero}
                                onChange={(e) => setGenero(e.target.value)}
                                size="small">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {comboGeneros.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3} className='mt-4'>
                            <TextField
                                fullWidth
                                id="carrera"
                                label="Carrera"
                                select
                                value={carrera}
                                onChange={handleChangeCarrera}
                                size="small">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {comboCarreras.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3} className='mt-4'>
                            <TextField fullWidth id="ciclo" label="Ciclo"
                                select
                                value={ciclo}
                                onChange={(e) => setCiclo(e.target.value)}
                                size="small">
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {comboCiclos.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3} className='mt-4'>
                            <Autocomplete
                                value={alumno}
                                onChange={(_, newVal) => {
                                    setAlumno(newVal)
                                }}
                                size='small'
                                options={comboAlumnos || []}
                                renderInput={(params) => <TextField {...params} label="Nombre Alumno" />}
                            />
                        </Grid>
                        <Grid item xs={3} className='mt-4'>
                            <Button fullWidth variant='contained' onClick={() => onFilter()}>Filtrar</Button>
                        </Grid>
                        <Grid item className='mt-4' xs={15}>
                            <DataGridStyle
                                height={320}
                                rows={filter ? dataAlumnos || [] : alumnos || []}
                                columns={columns}
                                checkboxSelection
                                getRowId={(row) => row.codEstudiante}
                                setAlumnosSeleccionados={setAlumnosSeleccionados}
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CreateTicket alumnosSeleccionados={alumnosSeleccionados} />
                        </Grid>
                    </Grid>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}
