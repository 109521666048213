import { Grid } from '@mui/material'
import React from 'react'
import ContentMainBox from '../../../components/MainBox'
import { Schedule } from './components/Schedule'

export const MiHorario = () => {
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mi Horario
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <Schedule />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}