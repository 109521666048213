import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async ({queryKey}) => {
  const { full_path: fullPath } = useUsuario()
  const [_key, codDocente] = queryKey
  const { data } = await axios.get(`${fullPath}/Ticket/${codDocente}`)

  return data.tickets
}

export const Create = async body => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.post(`${fullPath}/Ticket/ticket-alumnos`, body)

  return data.data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.put(
    `${fullPath}/ticket/${id}`,
    body
  )

  return data.data
}

export const GetCiclos = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Ticket/ciclo`)
  return data.ciclos

}
export const GetGeneros = async () => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.get(`${fullPath}/Ticket/genero`)
  return data.generos

}
export const GetCarreras = async ({queryKey}) => {
  const { full_path: fullPath } = useUsuario()
  const [_key, codDocente] = queryKey
  const { data } = await axios.get(`${fullPath}/Ticket/carreras-departamento/${codDocente}`)
  return data.carreras
}

