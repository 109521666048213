import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { setClient } from '../api/axios'
import { useUsuario } from '../hooks/useUsuario'
import { USER_ROL } from '../config/user-rol'

export const LoginGoogleService = async (googleToken, userData) => {
    const { full_path: fullPath } = useUsuario()
    const { data } = await axios.post(
        `${fullPath}/autenticacion/iniciar-sesion`, {
        correo: userData.email,
        rol: USER_ROL,
        googleToken
    })

    if (!data) {
        return { success: false, formErrors: data }
    }

    const token = data.datos

    const decodedToken = jwtDecode(token, {
        complete: true
    });

    setClient(token)
    return {
        success: true, usuario: decodedToken, token
    }
}

export const LoginService = async dataLogin => {
    const { full_path: fullPath } = useUsuario()
    const { data } = await axios.post(
        `${fullPath}/autenticacion/iniciar-sesion`,
        dataLogin,
        {
            headers: { Authorization: 'nt' },
        })

    if (!data) {
        return { success: false, formErrors: data }
    }

    const token = data.datos

    const decodedToken = jwtDecode(token, {
        complete: true
    });

    setClient(token)
    return {
        success: true, usuario: decodedToken, token
    }
}

export const isLoggedIn = () => {
    try {
        const token = localStorage.getItem("token");

        if (!token) return false;

        setClient(token)

        const decodedToken = jwtDecode(token, {
            complete: true
        });

        const dateNow = new Date();
        if (decodedToken.exp < dateNow.getTime() / 1000) {
            localStorage.clear()
            return false;
        }

        if (decodedToken?.rol !== USER_ROL) {
            localStorage.clear()
            return false
        }

        return true

    } catch (error) {
        localStorage.clear()
        return false
    }
}
