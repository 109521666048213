import { useState } from 'react'
import { useGetSemaforoByDni } from '../../../../../hooks/clinica/usePaciente'
import { Visibility } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { VerSemaforos } from './VerSemaforos'
// import VisibilityIcon from '@mui/icons-material/Visibility';

export const ButtonVerSemaforos = ({ dniEstudiante }) => {
  const [open, setOpen] = useState(false)
  const { data, refetch } = useGetSemaforoByDni(dniEstudiante, false)
  const handleClickOpen = () => {
    setOpen(true)
    refetch()
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Visibility />
      </IconButton>
      <VerSemaforos
        open={open}
        onClose={handleClose}
        data={data || []}
      />
    </div>
  )
}
