import { yupResolver } from '@hookform/resolvers/yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../../../components/Input'
import { useAlertMessage } from '../../../../../context/notifications'
import { useAuthContext } from '../../../../../context/userContext'
import { useGetAllSesion } from '../../../../../hooks/useSesion'

const ticketSchema = yup.object({
  tema: yup
    .string()
    .required('Campo requerido'),
  codSesion: yup
    .string()
    .required('Campo requerido'),
  hora: yup
    .string()
    .required('Campo requerido'),
  fechaTicket: yup
    .date()
    .required('Campo requerido'),
  tiempoSesion: yup
    .string()
    .required('Campo requerido'),
  tipoSesion: yup
    .string()
    .required('Campo requerido'),
  ubicacionSesion: yup
    .string()
    .required('Campo requerido'),
})
const tiempos = [
  {
    value: '1',
    denominacion: '1 hora'
  },
  {
    value: '2',
    denominacion: '1.5 horas'
  },
  {
    value: '3',
    denominacion: '2 horas'
  },
]
const tipos = [
  {
    value: '1',
    denominacion: 'presencial'
  },
  {
    value: '2',
    denominacion: 'virtual'
  }
]
const FormTicket = ({ edit, data, mutate, isLoading, alumnosSeleccionados }) => {
  const { user } = useAuthContext()
  const { data: sesiones } = useGetAllSesion()
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')
  const { showError, showSuccess } = useAlertMessage()

  const newSesiones =
    sesiones &&
    sesiones.map((unidad) => {
      return { value: unidad.codSesion, denominacion: unidad.tituloSesion }
    })
  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ticketSchema),
    defaultValues: {
      tema: '',
      codSesion: '',
      hora: '',
      fechaTicket: new Date().toJSON().slice(0, 10),
      tiempoSesion: tiempos[0],
      tipoSesion: tipos[0].denominacion,
      ubicacionSesion: 'IN203',
      codDocente: user?.codigo,
      estado: 1,
      userCreacion: '',
      urlMaterial: '',
      codAlumnos: alumnosSeleccionados
    }
  })
  const handleOpen = () => {
    if (edit) {
      reset({
        tema: data.tema,
        codSesion: data.codSesion,
        hora: data.hora,
        fechaTicket: data.fechaTicket,
        tiempoSesion: data.tiempoSesion,
        tipoSesion: data.tipoSesion,
        ubicacionSesion: data.ubicacionSesion,
        codDocente: user?.codigo,
        estado: 1,
        userCreacion: data.userCreacion,
        urlMaterial: data.urlMaterial,
        codAlumnos: alumnosSeleccionados
      })
    }
    setOpen(true)
  }
  const openModal = () => {
    alumnosSeleccionados.length > 0
      ? setOpen(true)
      : showError('Debe seleccionar a lo menos un alumno')
  }
  const handleClose = () => setOpen(false)

  const onSubmit = data => {
    const formData = {
      tema: data.tema,
      codSesion: data.codSesion,
      hora: data.hora,
      fechaTicket: data.fechaTicket,
      tiempoSesion: data.tiempoSesion,
      tipoSesion: tipoSesion,
      ubicacionSesion: data.ubicacionSesion,
      codDocente: user.codigo,
      estado: 1,
      userCreacion: data.userCreacion,
      urlMaterial: data.urlMaterial,
      codAlumnos: alumnosSeleccionados
    }
    const msg = edit ? 'No se pudo editar ticket' : 'No se pudo crear ticket'
    const msg2 = edit
      ? 'ticket editado correctamente'
      : 'ticket creado correctamente'
    mutate(formData, {
      onError: () => {
        handleClose()
        showError(msg)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }

  const [tipoSesion, settipoSesion] = useState('presencial')
  return (
    <>
      {edit ? (
        <Tooltip title='Editar ticket'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          fullWidth
          variant='contained'
          onClick={openModal}
        >
          Crear Ticket
        </Button>
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR TICKET' : 'CREAR TICKET'}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3} columns={2} alignContent='center'>
              <Grid item xs={2}>
                <Input
                  control={control}
                  id='tema'
                  label='Nombre de tema'
                  placeholder='Ingrese nombre de tema'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.tema?.message}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Input
                  control={control}
                  id='codSesion'
                  label='Sesión'
                  type='select'
                  data={newSesiones || []}
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.codSesion?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Input
                  control={control}
                  id='hora'
                  label='Hora de inicio'
                  type='time'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.hora?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Input
                  control={control}
                  id='fechaTicket'
                  label='FechaTicket'
                  type='date'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.fechaTicket?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Input
                  control={control}
                  id='tiempoSesion'
                  label='Tiempo de sesión'
                  type='select'
                  data={tiempos}
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.tiempoSesion?.message}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label='tipo de reunión'
                  value={tipoSesion}
                  onChange={(event) => {
                    settipoSesion(event.target.value)
                  }
                  }
                  variant="outlined"
                  size="small"
                  select
                  fullWidth
                >
                  {tipos.map((elemento, index) =>
                    <MenuItem key={index} value={elemento.denominacion}>{elemento.denominacion}</MenuItem>)}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <Input
                  control={control}
                  id='ubicacionSesion'
                  label={tipoSesion === 'presencial'
                    ? 'Nombre de aula'
                    : 'Link de reunión'
                  }
                  placeholder={tipoSesion === 'presencial'
                    ? null
                    : 'Ingrese link de reunión'
                  }
                  disabled={tipoSesion === 'presencial'
                    ? true
                    : null
                  }
                />

                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.ubicacionSesion?.message}
                </Typography>
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.codAlumnos?.message}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button fullWidth variant='outlined' component="label" size='small' startIcon={<DriveFolderUploadRoundedIcon />} >
                  subir material
                  <input hidden accept="image/jpeg,image/gif,image/png,application/pdf" multiple type="file" onChange={(e) => console.log(e.target.files)} />
                </Button>
              </Grid>
              {edit &&
                <Grid item xs={2}>
                  <Input
                    control={control}
                    id='observacion'
                    label='Observacion'
                    placeholder='Ingrese observacion'
                    multiline
                    rows={2}
                  />
                </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()

              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default FormTicket
