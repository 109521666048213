import FormTicket from './TicketForm'
import { useCreateTicket } from '../../../../../hooks/useTicket'

const CreateTicket = ({ alumnosSeleccionados }) => {
  const { mutate, isLoading } = useCreateTicket()
  return (
    <>
      <FormTicket edit={false} mutate={mutate} alumnosSeleccionados={alumnosSeleccionados} isLoading={isLoading} />
    </>
  )
}

export default CreateTicket
