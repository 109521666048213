import { Grid, IconButton } from '@mui/material'
import React, { useContext } from 'react'
import ContentMainBox from '../../../components/MainBox'
import { DataGridStyle } from '../../../components/DataGridStyle'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import UpdateTicket from '../CrearTicket/components/Ticket/UpdateTicket'
import { useGetAllTicket } from '../../../hooks/useTicket'
import { useAuthContext, UserContext } from '../../../context/userContext'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'codAlumnos',
    headerName: 'Tutorado',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'tema',
    headerName: 'Nombre tema',
    width: 300,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecTicket',
    headerName: 'Fecha',
    type: 'date',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora Inicio',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'nomSesion',
    headerName: 'Sesión',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'acciones',
    type: 'actions',
    headerName: 'Acciones',
    cellClassName: 'acciones',
    getActions: ({ id, row }) => {
      console.log(id, row)
      return [
        <IconButton
          aria-label="acciones"
        >
          {row.estado === 'APROBADO' ? <CheckCircleOutlineRoundedIcon color='primary' /> : <HighlightOffRoundedIcon color='secondary' />}
        </IconButton>,
        <UpdateTicket data={row} id_Ticket={id} />
      ]
    }
  }
]

export const MisTickets = () => {
  const { user } = useAuthContext()
  const { data: tickets } = useGetAllTicket(user.codigo)
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mis Tickets
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <DataGridStyle
                height={380}
                rows={tickets || []}
                columns={columns}
                getRowId={(row) => row.codTikect}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
