import MedicationLiquidRoundedIcon from '@mui/icons-material/MedicationLiquidRounded'
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react'
import { Link } from 'react-router-dom'

export const ButtonCitaMedica = ({ codEstudiante }) => {
    const handleClickOpen = () => {
    }
    return (
        <div>
            <Link
                key="notunique"
                to={`/docente/creartickets/citamedica/${codEstudiante}`}
            >
                <Tooltip title="Cita Médica">
  

                <IconButton
                    onClick={handleClickOpen}
                >
                    <MedicationLiquidRoundedIcon />
                </IconButton>
                </Tooltip>
            </Link>
        </div>
    )
}