import axios from 'axios'

export const axiosClient = axios.create({
})

export function setClient(token) {
    axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const axiosClinicaClient = axios.create({
    baseURL: process.env.REACT_APP_CLINICA_API_URL
})