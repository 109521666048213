import { Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material'
import React from 'react'
import { DataGridStyle } from '../../../../../components/DataGridStyle'
import { Circle } from '@mui/icons-material'
import { render } from '@testing-library/react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const semaforoMapper = {
  0: 'Verde',
  1: 'Naranja',
  2: 'Rojo'
}
const colorMapper = {
  0: '#4CAF50',
  1: '#FFC107',
  2: '#F44336'
}

export const VerSemaforos = props => {
  const { onClose, selectedValue, data, open } = props
  const columns = [
    {
      headerClassName: 'super-app-theme--header',
      field: 'idCita',
      headerName: 'IdCita',
      type: 'string',
      width: 180
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'fechaCita',
      headerName: 'Fecha cita',
      type: 'date',
      width: 180,
      renderCell: params => params.value.split('T')[0]
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'desEspecialidad',
      headerName: 'Especialidad',
      type: 'string',
      width: 180
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'semaforo',
      headerName: 'Semáforo',
      width: 370,
      type: 'string',
      renderCell: params => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Circle sx={{ color: colorMapper[params.value] }} />
          <span>{semaforoMapper[params.value]}</span>
        </div>
      )
    }
  ]

  const handleClose = () => {
    onClose(selectedValue)
  }
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Semaforos del estudiante</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={12}>
              <DataGridStyle
                height={400}
                rows={data}
                columns={columns}
                getRowId={row => row.idCita}
                experimentalFeatures={{ newEditingApi: true }}
                rowsPerPageOptions={[5]}
                pageSize={5}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
