import TicketForm from './TicketForm'
import { useUpdateTicket } from '../../../../../hooks/useTicket'

const UpdateTicket = ({ data, id_Ticket }) => {
  const { mutate } = useUpdateTicket(id_Ticket)
  return (
    <>
      <TicketForm edit={true} data={data} mutate={mutate} />
    </>
  )
}

export default UpdateTicket
