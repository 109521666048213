import styled from '@emotion/styled'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Toolbar, Typography } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { useAuthContext } from '../../context/userContext'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const HeaderLayout = ({ handleDrawerOpen, open, setOpen }) => {
  const { user } = useAuthContext()

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' })
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" className='text-end mx-2' sx={{ flexGrow: 1 }}>
          {
            user?.nombres
          }
        </Typography>
        <AccountCircleRoundedIcon fontSize='large' />
      </Toolbar>
    </AppBar>
  )
}

export default HeaderLayout
