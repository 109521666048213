import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  GetAll,
  Create,
  Update,
  GetCiclos,
  GetGeneros,
  GetCarreras,
} from '../services/ticket'

const key = 'ticket'

export const useGetAllTicket = (codDocente, enabled = true) =>
  useQuery([key, codDocente], GetAll, {
    enabled
  })

export const useCreateTicket = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdateTicket = idTicket => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(idTicket, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useGetCiclos = (enabled = true) =>
  useQuery('ciclos', GetCiclos, {
    enabled
  })
export const useGetGeneros = (enabled = true) =>
  useQuery('generos', GetGeneros, {
    enabled
  })
  export const useGetCarreras = ( codDocente ,enabled = true) =>
  useQuery(['carreras', codDocente], GetCarreras, {
    enabled
  })
