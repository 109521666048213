import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import ContentMainBox from '../../../../../components/MainBox'
import Input from '../../../../../components/Input'

const SemestreSchema = yup.object({
  codEspecialidad: yup
    .string()
    .required('Campo requerido'),
  codEspecialista: yup
    .string()
    .required('Campo requerido'),
  fechaCita: yup
    .date('Ingresar una fecha válida')
    .required('Campo requerido'),
  horaCita: yup
    .string()
    .required('Campo requerido'),
  estado: yup
    .string()
    .required('Campo requerido'),
})

export const FormCita = () => {
    let { codEstudiante } = useParams()
  const tutores = undefined
  const comboEspecialidades =
    tutores ?
      tutores.map((tutor) => {
        return { value: tutor.codDocente, denominacion: tutor.codDocente + ' ' + tutor.nomDocente }
      }) : [
        {
          value: '0001',
          denominacion: 'Odontología'
        },
        {
          value: '0002',
          denominacion: 'Psicología'
        },
      ]
  const comboEspecialistas =
    tutores ?
      tutores.map((tutor) => {
        return { value: tutor.codDocente, denominacion: tutor.codDocente + ' ' + tutor.nomDocente }
      }) : [
        {
          value: '0001',
          denominacion: 'Juan Bautista'
        },
        {
          value: '0002',
          denominacion: 'Mariela Carrión'
        },
      ]

  const { handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SemestreSchema),
    defaultValues: {
      codEstudiante: codEstudiante,
      codEspecialidad: '',
      codEspecialista: '',
      fechaCita: new Date().toJSON().slice(0, 10),
      horaCita: '',
      estado: 'En espera'
    }
  })
  console.log(comboEspecialidades)
  console.log(comboEspecialistas)
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Reservar cita
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form>
            <Grid container spacing={0} className='mt-2'>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='codEspecialidad'
                      label='Especialidad'
                      type='select'
                      data={comboEspecialidades}
                    />
                    <Typography
                      variant='subtitle2'
                      align='left'
                      color='red'
                    >
                      {errors.codEspecialidad?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='codEspecialista'
                      label='Especialista'
                      type='select'
                      data={comboEspecialistas}
                    />
                    <Typography
                      variant='subtitle2'
                      align='left'
                      color='red'
                    >
                      {errors.codEspecialidad?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='fechaCita'
                      label='Fecha'
                      type='date'
                    />
                    <Typography
                      variant='subtitle2'
                      align='left'
                      color='red'
                    >
                      {errors.fechaCita?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='horaCita'
                      label='Hora'
                      type='time'
                    />
                    <Typography
                      variant='subtitle2'
                      align='left'
                      color='red'
                    >
                      {errors.horaCita?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <Button type='submit' fullWidth variant="contained" color='primary' >
                      Crear Cita
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
