import jwtDecode from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { USER_ROL } from '../config/user-rol';

const UserContext = createContext()

const decodedToken = () => {
    try {
        const token = localStorage.getItem("token");
        if (!token) return false;

        const decodedToken = jwtDecode(token, {
            complete: true
        });

        const dateNow = new Date();

        if (decodedToken.exp < dateNow.getTime() / 1000) {
            localStorage.clear()
            return null
        }

        if (decodedToken?.rol !== USER_ROL) {
            return null
        }

        return decodedToken;
    } catch (error) {
        localStorage.clear()
        return null
    }
}


export const UserProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("token"))
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) ?? {
        nombres: '',
        codigo: ''
    })

    const [loading, setLoading] = useState(true)

    const localToken = localStorage.getItem("token")

    useEffect(() => {
        const user = decodedToken()

        if (!user) {
            localStorage.clear()
        }

        localStorage.setItem("user", JSON.stringify(user))
        setUser(user)
        setToken(localToken)
        setLoading(false)
    }, [])

    const logout = () => {
        localStorage.clear()
    }

    if (loading) return null

    return (
        <UserContext.Provider value={{
            token,
            setToken,
            user,
            setUser,
            logout,
        }}>
            {children}
        </UserContext.Provider>
    )
}

export const useAuthContext = () => {
    const ctx = useContext(UserContext)
    const navigate = useNavigate()

    if (!ctx) {
        throw Error("Context Error")
    }

    const localToken = localStorage.getItem("token")
    const localUser = localStorage.getItem("user")

    useEffect(() => {
        const user = decodedToken()

        if (!user) {
            localStorage.clear()
            navigate('/login')
        }

        localStorage.setItem("user", JSON.stringify(user))
        ctx.setUser(user)
        ctx.setToken(localToken)
    }, [localToken, localUser])

    return ctx
} 