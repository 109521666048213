import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'
import { Grid, Tooltip, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../context/userContext'

const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const Sidebar = ({ handleDrawerClose, open, navConfig, history }) => {
  const { user, logout } = useAuthContext()
  const { links } = navConfig()
  const theme = useTheme()

  return (
    <Drawer variant='permanent' open={open}>
      <DrawerHeader className='bg-[#217E93]'>
        <IconButton onClick={handleDrawerClose} className='text-[#B5E3E8]'>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className='bg-[#F2F4F4]'>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          sx={{ opacity: open ? 1 : 0 }}

        >
          <Grid item>
            <AccountCircleRoundedIcon fontSize='large' className='text-[#77898B] ' />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className='text-[#217E93] text-center mb-4'>{user?.nombres}</Typography>
            <Typography variant="h6" className='text-[#217E93] text-center mb-4'>Tutor</Typography>
          </Grid>
        </Grid>
        {links.map((element, index) => (
          <Link
            to={element.path}
            className='no-underline'
            style={{ color: theme.palette.text.primary }}
            key={index}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5
              }}
            >
              <ListItemIcon
                className='text-[#217E93]'
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center'
                }}
              >
                <Tooltip title={element.toolTip}>
                  <element.icon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                className='text-[#217E93]'
                primary={element.name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ))}
      </List>
      <Divider />
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          height: '100%',
        }}
      >
        <Link
          to={'/login'}
          className='no-underline'
          style={{ color: theme.palette.text.primary }}
        >
          <ListItemButton
            sx={{
              maxHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5
            }}
            onClick={logout}
          >
            <ListItemIcon
              className='text-[#217E93]'
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <Tooltip title='Cerrar sesión'>
                <ExitToAppRoundedIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              className='text-[#217E93]'
              primary='Cerrar sesión'
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      </List>
      {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItemButton
              key={text}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          ))}
        </List> */}
    </Drawer>
  )
}

export default Sidebar
