import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../../components/layout/dashboard'
import Inicio from './inicio'
import { docenteNavConfig } from './layoutDocente'
import { MisTickets } from './MisTickets'
import { CrearTicket } from './CrearTicket'
import { FormCita } from './CrearTicket/components/CitaMedica/FormCita'
import { MiHorario } from './MiHorario'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { USER_ROL } from '../../config/user-rol'

const DocenteDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard navConfig={docenteNavConfig} />}>
        <Route element={<ProtectedRoute />}>
          <Route index element={<Inicio />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="creartickets" element={<CrearTicket />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="creartickets/citamedica/:codEstudiante" element={<FormCita />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="mistickets" element={<MisTickets />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="miHorario" element={<MiHorario />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="*" element={<Navigate to={`/${USER_ROL}`} />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default DocenteDashboard
