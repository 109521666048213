import { Box, Typography } from "@mui/material"
import { useState } from "react"

export const Schedule = () => {
  const days = ['Hora', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
  const hours = ['7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00']
  const [mySchedule, setMySchedule] = useState([
    // {
    //   day: 'Lunes',
    //   schedule: ['7:00', '8:00']
    // }
  ])
  const mapMySchedule = (day) => {
    const find = mySchedule.find(item => item.day === day)
    return find ? find.schedule : []
  }

  const handleSelect = (hour, day) => {
    const find = mySchedule.find(item => item.day === day)

    if (!find) {
      return setMySchedule([...mySchedule, { day, schedule: [hour] }])
    }

    if (find) {
      const findHour = find.schedule.find(item => item === hour)
      if (findHour) {
        const newSchedule = mySchedule.map(item => {
          if (item.day === day) {
            return {
              ...item,
              schedule: item.schedule.filter(item => item !== hour)
            }
          }
          return item
        })
        setMySchedule(newSchedule)
      } else {
        const newSchedule = mySchedule.map(item => {
          if (item.day === day) {
            return {
              ...item,
              schedule: [...item.schedule, hour]
            }
          }
          return item
        })
        setMySchedule(newSchedule)
      }
    }
    console.log(mySchedule)
  }

  return (
    <Box className='flex justify-around items-center'>
      {
        days.map((day, idx) => (
          <Box className='w-full m-1' key={idx}>
            <Typography variant='subtitle1' align='center'>{day}</Typography>
            {
              hours.map((hour, index) =>
                <div
                  className={`h-10 rounded mb-1 ${mapMySchedule(day).includes(hour) ? 'bg-green-400' : 'bg-slate-200'} flex justify-center items-center`}
                  onClick={() => handleSelect(hour, day)}
                  key={index}
                >
                  {idx !== 0 ? '' : hour}
                </div>
              )
            }
          </Box>
        ))
      }
    </Box >
  )
}